<script lang="ts">
  import classnames from 'classnames';
  import { omit } from '$lib/utils/object';
  // Components
  import { Grid } from "$lib/components/organisms/Layout";

  export let noPaddingTop = false;
  export let noPaddingBottom = false;
  export let noHorizontalPadding = false;
  export let compact = false;
  /* Extend the mobile grid beyond page margins, for different breakpoints */
  export let extended = false;
  export let extendedScrollable = false;

  const attributes = omit($$restProps, 'class');
</script>

<section
  class={classnames('generic-section', $$props.class)}
  class:no-padding-top={noPaddingTop}
  class:no-padding-bottom={noPaddingBottom}
  {...attributes}
>
  {#if $$slots.header}
    <div class="header" class:compact>
      <Grid noGrid {noHorizontalPadding}>
        <div class="header-content">
          <div class="header-body">
            <slot name="header" />
          </div>

          {#if $$slots['indicators']}
            <div class="indicators">
              <slot name="indicators" />
            </div>
          {/if}

          {#if $$slots['filter']}
            <div class="filter">
              <slot name="filter" />
            </div>
          {/if}
        </div>
      </Grid>
    </div>
  {/if}
  <div class="content" class:extended class:extendedScrollable>
    <slot />
  </div>
  {#if $$slots.footer}
    <div class="footer">
      <Grid noGrid {noHorizontalPadding}>
        <slot name="footer" />
      </Grid>
    </div>
  {/if}
  {#if $$slots.complexFooter}
    <div class="footer">
      <Grid noGrid {noHorizontalPadding}>
        <div class="grid gap-y-4 xs:flex xs:justify-end xs:space-x-8">
          <slot name="complexFooter" />
        </div>
      </Grid>
    </div>
  {/if}
</section>

<style type="text/scss" lang="scss" scoped>
  .generic-section {
    @apply py-6;
    @apply md:py-14;
  }

  .no-padding-top {
    @apply pt-0;
  }

  .no-padding-bottom {
    @apply pb-0;
  }

  .header {
    &:not(.compact) {
      @apply mb-5 md:mb-14;
    }

    &.compact {
      @apply mb-4 md:mb-6;
    }
  }

  .content {
    &.extended {
      @apply -page-margin md:mx-0;
    }
    &.extendedScrollable {
      @apply -page-margin;
    }
  }

  .header-content {
    @apply flex;
    @apply flex-col md:flex-row;
    @apply flex-wrap md:flex-nowrap;
  }

  .header-body {
    @apply flex-grow;
  }

  .indicators {
    @apply flex;
    @apply md:-mb-4; /** SectionCarouselIndicator bottom padding */
  }

  .filter {
    @apply mt-4 md:mt-0 md:ml-6;
    @apply ml-auto;
    @apply flex-nowrap;
    min-width: 100px;
  }

  .footer {
    @apply mt-6;
    @apply text-right;
  }
</style>
